import React from 'react';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../../components/Layout';
import Cover from '../../../components/Elements/Cover';
import PageSection from '../../../components/Elements/PageSection';

import Icon from '../../../components/Elements/Icon';
import GetReady from '../../../components/Parts/GetReady';
import Row, { Col } from '../../../components/Elements/Grid';
import LinkButton from '../../../components/Elements/LinkButton';
import withTranslations from '../../../i18n/withTranslations';

const Page = () => {
  const { t } = useTranslation('onzeAanpakWorkflow');

  return (
    <Layout>
      <Seo title={t('seoTitle')} />
      <Cover image="avocado">
        <PageSection center className="steps">
          <h1>{t('title')}</h1>
          <div className="plan">
            <div className="step">
              <h3>{t('monitoring.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="heartbeat" />
                </div>
                <div className="story">
                  <p>{t('monitoring.description.p1')}</p>
                  <p>{t('monitoring.description.p2')}</p>
                </div>
              </div>
            </div>
            <div className="step">
              <h3>{t('optimization.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="exo-mushroom" />
                </div>
                <div className="story">
                  <p>{t('optimization.description.p1')}</p>
                  <p>{t('optimization.description.p2')}</p>
                </div>
              </div>
            </div>
            <div className="step">
              <h3>{t('backups.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="history" />
                </div>
                <div className="story">
                  <p>{t('backups.description.p1')}</p>
                  <p>{t('backups.description.p2')}</p>
                </div>
              </div>
            </div>
            <div className="step">
              <h3>{t('patchManagement.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="band-aid" />
                </div>
                <div className="story">
                  <p>{t('patchManagement.description.p1')}</p>
                  <p>{t('patchManagement.description.p2')}</p>
                </div>
              </div>
            </div>
            <div className="step">
              <h3>{t('changeManagement.title')}</h3>
              <div className="details">
                <div className="icon">
                  <Icon type="code-branch" />
                </div>
                <div className="story">
                  <p>{t('changeManagement.description.p1')}</p>
                  <p>{t('changeManagement.description.p2')}</p>
                </div>
              </div>
            </div>
          </div>
        </PageSection>
        <PageSection slide center>
          <Row>
            <Col className="quote">
              <h2 className="text-center">{t('customerQuote.title')}</h2>
              <p>
                &ldquo;
                {t('customerQuote.description')}
                &rdquo;
              </p>
              <div className="foot">
                <h3>Bas, Greenberry</h3>
                <LinkButton to="/onze-aanpak/cases#greenberry">
                  {t('customerQuote.watchCase')}
                </LinkButton>
              </div>
            </Col>
          </Row>
        </PageSection>
        <GetReady title={t('getReadyTitle')} image="footerWorkflow" />
      </Cover>
    </Layout>
  );
};

export default withTranslations(Page);
